var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bar-status", class: _vm.uploadProcessStatus },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "content-wrap" },
          [
            _vm.processing
              ? _c("span", {
                  staticClass: "ui active inline loader inverted small icon"
                })
              : _c("t-icon", {
                  staticClass: "icon",
                  attrs: { icon: _vm.iconStatus }
                }),
            _c("span", {
              staticClass: "info",
              domProps: { innerHTML: _vm._s(_vm.statusTextInfo) }
            })
          ],
          1
        ),
        _vm.hasButton && !_vm.processing && _vm.uploadProcessStatus != "failed"
          ? _c("div", { staticClass: "action-button" }, [
              _c(
                "a",
                {
                  staticClass: "tui ui button inverted",
                  attrs: { href: _vm.urlFileNewShipment },
                  on: { click: _vm.closeBarStatus }
                },
                [_vm._v("Baixar")]
              )
            ])
          : _c(
              "div",
              { staticClass: "close-status" },
              [
                _c("t-icon", {
                  attrs: { icon: ["fas", "times"] },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closeBarStatus($event)
                    }
                  }
                })
              ],
              1
            )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }