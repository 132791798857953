import { mapState } from 'vuex'

export default {
  name: 'TBarStatus',
  props: {
    showBarStatus: {
      type: Boolean,
      default: true
    },
    progress: {
      type: Number,
      default: 0
    },
    hasError: {
      type: Boolean,
      default: false
    },
    statusTextInfo: {
      type: String,
      default: ''
    },
    hasButton: {
      type: Boolean,
      default: false
    },
    processCompleted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      urlFileNewShipment: ({ files }) => files.urlFileNewShipment,
    }),
    iconStatus () {
      if (this.processCompleted) {
        if (this.hasError && this.progress !== 0) {
          return [ 'far', 'times-circle' ]
        } else if (this.progress === 100 && !this.hasError) {
          return [ 'fas', 'check' ]
        }
      }
      return ''
    },

    processing () {
      return (this.progress !== 0 && this.progress < 100)
    },

    uploadProcessStatus () {
      if (this.processCompleted) {
        if (this.hasError && this.progress !== 0) {
          return 'failed'
        } else if (this.progress === 100 && !this.hasError) {
          return 'success'
        }
      } else if (this.processing) {
        return 'processing'
      } else {
        return ''
      }
    }
  },
  methods: {
    closeBarStatus () {
      this.$emit('closeBarStatus')
    }
  }
}
